<template>
  <v-popover offset="10"
             :placement="popoverPlacement"
             trigger="hover"
             popoverClass="dashboard_popover"
             popoverWrapperClass="dashboard_popover_wrapper"
             popoverInnerClass="dashboard_popover_content"
             boundariesElement=".popover_trigger"
             @show="getTargetGroupDetails()">

    <div class="popover_trigger">      
      <div class="pointer" @click="openSidebarRight('headcount_details', { headcount_type: targetGroupsType, headcount_id_external: targetGroupsIdExternal })">
        <i class="icon top fas fa-users-cog"/>
      </div>      
    </div>

    <template slot="popover">
      <CCard  class="mb-0">
        <CCardBody>
          <CRow v-if="loadingData === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="item_tags">
                <b-tag v-for="group in targetGroups" v-bind:key="group.group_id" class="mt-1 info">{{group.title}}</b-tag>
              </div>
            </CCol>
          </CRow>                    
        </CCardBody>
      </CCard>
    </template>
  </v-popover>
</template>

<script>
import axios from 'axios';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'targetGroupPopover',
  props: ['targetGroupsType', 'targetGroupsIdExternal', 'popoverPlacement'],
  components: {
    loadingSpinner
  },  
  watch: {
    $props: {
      handler() {
        if(this.targetGroupsType === 'knowledge category') {
          this.detailsPath = 'knowledge/category';
        } else if(this.targetGroupsType === 'knowledge subcategory') {
          this.detailsPath = 'knowledge/subcategory';
        } else if(this.targetGroupsType === 'learn category') {
          this.detailsPath = 'learn/category';
        } else if(this.targetGroupsType === 'learn course') {
          this.detailsPath = 'learn/course';
        } else if(this.targetGroupsType === 'learn onboarding package') {
          this.detailsPath = 'learn/onboarding/package';
        } else if(this.targetGroupsType === 'loyalty store category') {
          this.detailsPath = 'loyalty/category';
        } else if(this.targetGroupsType === 'measure category') {
          this.detailsPath = 'measure/satisquestions/category';
        } else if(this.targetGroupsType === 'measure burning matter') {
          this.detailsPath = 'measure/burning_matter';
        } else if(this.targetGroupsType === 'service form category') {
          this.detailsPath = 'service/forms/category';
        } else if(this.targetGroupsType === 'service questions folder') {
          this.detailsPath = 'service/questions/folder';
        } else if(this.targetGroupsType === 'core route') {
          this.detailsPath = 'core/route';
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      loadingData: false,
      targetGroupsLoaded: false,
      detailsPath: null,
      targetGroups: []
    }
  },
  methods: {
    getTargetGroupDetails() {
      if(this.targetGroupsLoaded === false) {
        // Show the loader
        this.loadingData = true;
        // Get the target groups
        axios.get(process.env.VUE_APP_API_URL + '/v1/' + this.detailsPath + '/' + this.targetGroupsIdExternal)
        .then(res => {
          this.targetGroups = res.data.data.target_groups;
          
          setTimeout(function() {
            // Hide the loader
            this.loadingData = false;
            // Update the profile Loaded value
            this.targetGroupsLoaded = true;
          }.bind(this), 250);           
        })
        .catch(err => {
          console.error(err); 
        });
      }
    }
  }    
}
</script>